<template>
  <div>
    <div class="font-bold text-111 text-xl">{{ dataSource.Title }}</div>
    <a-radio-group
      :value="model[dataSource.ID]"
      v-if="dataSource.Type === 1"
      @change="$emit('select', $event.target.value)"
    >
      <a-radio
        class="block mt-20 text-sm text-999"
        :value="item.ID"
        v-for="item in dataSource.Options"
        :key="item.ID"
        >{{ item.Name }}</a-radio
      >
    </a-radio-group>
    <a-checkbox-group
      :value="model[dataSource.ID]"
      v-else-if="dataSource.Type === 2"
      @change="$emit('select', $event)"
    >
      <a-checkbox
        :class="width"
        class="mt-20 text-sm text-999"
        style="margin-left: 0"
        v-for="item in dataSource.Options"
        :key="item.ID"
        :value="item.ID"
        >{{ item.Name }}</a-checkbox
      >
    </a-checkbox-group>
  </div>
</template>

<script>
export default {
  props: {
    dataSource: {
      type: Object,
      default: () => ({}),
    },
    model: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      width: "w-1/3",
    };
  },
  created() {
    this.dataSource.Options.forEach((option) => {
      if (option.Name.length > 8) {
        this.width = "w-full";
      }
    });
  },
};
</script>
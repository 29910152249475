<template>
  <div
    class="
      login
      bg-white
      border border-eee
      relative
      flex
      rounded
      overflow-hidden
    "
  >
    <span class="absolute top-20 left-20 z-10">
      <router-link to="/auth/login" class="text-white text-sm">
        登录
      </router-link>
    </span>
    <div class="absolute top-0 left-0 flag border-primary"></div>
    <div class="divider bg-eee"></div>
    <div class="flex flex-col justify-center login-form px-40">
      <div class="text-28 font-bold text-111 text-center mb-20">注册</div>

      <a-form-model ref="container" :model="model1" :rules="rules">
        <a-form-model-item prop="companyName" v-if="model1.type === 3">
          <gf-input
            size="large"
            v-model="model1.companyName"
            placeholder="请输入公司名称，须与营业执照一致"
          ></gf-input>
        </a-form-model-item>
        <a-form-model-item prop="email">
          <gf-input
            :maxLength="100"
            size="large"
            v-model="model1.email"
            placeholder="请输入邮箱/手机"
          ></gf-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password
            :maxLength="20"
            size="large"
            type="password"
            v-model="model1.password"
            placeholder="请设置登录密码（不少于8位数）"
          ></a-input-password>
        </a-form-model-item>
        <a-form-model-item prop="code">
          <a-input
            :maxLength="6"
            size="large"
            v-model="model1.code"
            placeholder="请输入验证码"
          >
            <count-down
              slot="suffix"
              text="发送验证码"
              :count="60"
              :send="sendRegisterCode"
            >
            </count-down>
          </a-input>
        </a-form-model-item>
      </a-form-model>
      <a-button
        @click="register"
        class="w-full mt-40"
        type="primary"
        size="large"
        >下一步</a-button
      >
    </div>
    <div class="login-desc flex justify-center items-center">
      <img src="@/assets/images/login-inset.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { register, sendRegisterCode, login } from "@/api";
import { sendRegisterPhoneCode, getUserInfo } from "@/api/user";
import QuestionForm from "@/components1/QuestionForm/index.vue";
import { getQuestion, submitQuestion, getMyQuestion } from "@/api1/common";
import { localSet } from "@/utils/storage";

import { transformCompany } from '@/utils/transform/company.js'

import {
  validateEmail,
  validateTel,
  validatePassword,
} from "@/utils/validator";

function generateRole(res) {
  // 1 普通用户 2 专家用户 3 未认证企业用户 4 已认证企业用户 5 已认证企业会员用户 6 管理员 7 超级管理员 8 已认证普通用户
  if (res.type === 0) {
    return 7;
  } else if (res.type === 1) {
    return 6;
  } else if (res.type === 2) {
    if (res.is_expert) return 2;
    if (res.link_status === 2) return 8;
    return 1;
  } else if (res.type === 3) {
    if (res.authenticate === 2) {
      if (res.is_member) {
        return 5;
      } else {
        return 4;
      }
    } else {
      return 3;
    }
  }
}

export default {
  name: "register",
  components: { QuestionForm },
  data() {
    return {
      model1: {
        type: 3,
      },
      agree: false,
      visible1: false,
      type: "all",
      visible: false,
      question: [],
      nextId: 0,
      current: {},
      model: {},
      path: [],
      first: 0,
      animateFlag: "next",
    };
  },
  created() {
    this.getQuestion();
    this.questionFlag = this.$route.query.question;
  },
  computed: {
    isEmail() {
      return /[a-zA-Z@.]/.test(this.model1.email);
    },
    rules() {
      return {
        companyName: [
          {
            required: this.type === "all",
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入",
            trigger: "blur",
          },
          {
            validator: this.isEmail ? validateEmail : validateTel,
            message: "请输入正确的邮箱或手机格式",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: this.type === "all",
            message: "请输入登录密码",
            trigger: "blur",
          },
          {
            min: this.type === "all" ? 8 : 0,
            max: this.type === "all" ? 16 : 255,
            message: "登录密码长度8-16位",
            trigger: "blur",
          },
          {
            validator: validatePassword,
            message: "密码应包含数字、字母、字符中的两种或两种以上",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: this.type === "all",
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      };
    },
    okText() {
      return "下一页";
    },
    cancelText() {
      return "上一页";
    },
    okType() {
      return this.nextId ? "primary" : "disabled";
    },
    cancelType() {
      return this.current.ID !== this.first ? "primary" : "disabled";
    },
    index() {
      return this.current.ID;
    },
  },
  methods: {
    register() {
      this.type = "all";
      this.$refs.container.validate((valid) => {
        if (valid) {
          const { type, companyName, email, password, code } = this.model1;
          const temp = {
            companyName,
            password,
            code,
            type,
          };
          if (!this.isEmail) {
            temp.phone = email;
          } else {
            temp.email = email;
          }
          register(temp)
            .then(() => {
              this.$message.success("注册成功");
              const temp1 = {
                username: temp.email || temp.phone,
                password: temp.password,
              };
              login(temp1).then(({ code, data, msg }) => {
                const { token } = data;
                localSet("token", token);
                // this.$store.dispatch("user/GET_USER_INFO").then((res) => {
                //   this.getMyQuestion();
                //   this.$store.commit("setUserInfo", res);
                //   this.$store.commit("setRole", generateRole(res));
                //   this.visible1 = true;
                // });
                let info = {},
                  company = {};
                this.$store.dispatch("user/GET_USER_INFO").then((res) => {
                  info = res;
                  this.$store.dispatch("user/GET_COMPANY_INFO").then((res) => {
                    company = transformCompany(res)
                    this.$store.commit("setCompanyInfo", company);
                    const temp1 = {
                      uid: info.UID || info.id,
                      type: "click",
                      name: "register",
                      metadata: {
                        company: company.companyName,
                      },
                    };
                    this.$store.dispatch("track/setTrack", temp1);
                    this.$router.push("/");
                  });
                });
              });
            })
            .catch(({ msg }) => {
              this.$message.error(msg);
            });
        }
      });
    },
    async sendRegisterCode() {
      this.type = "";
      const promise = new Promise((resolve) => {
        this.$nextTick(() => {
          this.$refs.container.validate((valid) => {
            resolve(valid);
          });
        });
      });

      const result = await promise;
      if (!result) return;

      if (this.isEmail) {
        return sendRegisterCode({ email: this.model1.email });
      } else {
        return sendRegisterPhoneCode({ phone: this.model1.email });
      }
    },
    getQuestion() {
      getQuestion().then(({ data }) => {
        this.question = data.Detail.Items || [];
        this.current = this.question[0];
        this.nextId = this.current.NextItem;
        this.first = this.current.ID;
      });
    },
    submit() {
      const paths = [...this.path];
      paths.push(this.current);
      const temp = paths.map((data) => {
        const temp = { ...data };
        temp.Options = data.Options.filter((item) => {
          if (data.Type === 2) {
            return this.model[data.ID].includes(item.ID);
          } else {
            return item.ID === this.model[data.ID];
          }
        });
        return temp;
      });
      submitQuestion(undefined, { items: temp })
        .then(() => {
          this.$message.success("提交问卷成功");
          this.model = {};
          this.visible = false;
          window.location.href = "#/index";
        })
        .catch(() => {
          this.$message.error("提交问卷失败");
        });
    },
    ok() {
      this.animateFlag = "next";
      this.path.push({ ...this.current });
      this.current = this.question.find((item) => item.ID === this.nextId);
      this.nextId = this.current.NextItem;
    },
    cancel() {
      this.animateFlag = "";
      this.current = this.path.pop();
      this.nextId = this.current.NextItem;
      if (
        this.model[this.current.ID] &&
        !Array.isArray(this.model[this.current.ID])
      ) {
        let nextId = this.current.Options.find(
          (item) => item.ID === this.model[this.current.ID]
        ).NextItem;
        if (nextId) this.nextId = nextId;
      }
      window.location.href = "#/index";
    },
    select(e) {
      if (e) {
        this.$set(this.model, this.current.ID, e);
        let nextId;
        if (!Array.isArray(e)) {
          nextId = this.current.Options.find((item) => item.ID === e).NextItem;
          if (nextId) this.nextId = nextId;
        }
      }
    },
    getMyQuestion() {
      getMyQuestion()
        .then(() => {})
        .catch(() => {
          if (this.questionFlag) {
            this.visible = true;
          }
        });
    },
    cancel1() {
      this.$router.push("/");
    },
  },
  watch: {
    "model1.type": {
      handler() {
        this.$refs.container.clearValidate();
      },
    },
  },
};
</script>

<style scoped>
.login {
  width: 900px;
  height: 560px;
  box-shadow: 2px 5px 32px -10px rgba(0, 0, 0, 0.2);
}
.login-form {
  width: 400px;
}
.login-desc {
  width: 500px;
}
.flag {
  width: 100px;
  height: 100px;
  border-width: 50px;
  border-width: 50px;
  border-bottom-color: #fff;
  border-right-color: #fff;
}
.divider {
  position: absolute;
  left: 400px;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 360px;
}
p {
  margin-top: 10px;
}
h1 {
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
</style>